<template>
  <footer class="footer">
    <div class="container-fluid">
      <div class="row">
        <div class="col-sm-6"> {{ new Date().getFullYear() }}D.O.T V?</div>
        <div class="col-sm-6">
          <div class="text-sm-end d-none d-sm-block">Developed by D.O.T</div>
        </div>
      </div>
    </div>
  </footer>
  <!-- end footer -->
</template>
