<script>
import { layoutComputed } from "@/state/helpers";
import {useAccountStore} from '/src/stores/account'


/**
 * Horizontal-topbar component
 */
export default {
  props: {
    type: {
      type: String,
      required: true,
    },
    width: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...layoutComputed,
  },
  components: {
 
  },
  data() {
    return {
      accountStore: useAccountStore(),
      homeLink: '',
      domain:window.location.host.toUpperCase(),
    
      languages: [
        {
          flag: require("@/assets/images/flags/us.jpg"),
          language: "en",
          title: "English",
        },
        {
          flag: require("@/assets/images/flags/french.jpg"),
          language: "fr",
          title: "French",
        },
        {
          flag: require("@/assets/images/flags/spain.jpg"),
          language: "es",
          title: "spanish",
        },
        {
          flag: require("@/assets/images/flags/chaina.png"),
          language: "zh",
          title: "Chinese",
        },
        {
          flag: require("@/assets/images/flags/arabic.png"),
          language: "ar",
          title: "Arabic",
        },
      ],
    };
  },
  mounted() {

    

  },
  methods: {

    logOut(){

      localStorage.clear()

        this.$router.push('/login'); 
        location.reload()
        this.$store.commit('initializeSS')
  
       
      
      


    },

    toggleRightSidebar() {
      this.$parent.toggleRightSidebar();
    },
    toggleMenu() {
      let element = document.getElementById("topnav-menu-content");
      element.classList.toggle("show");
    },
    initFullScreen() {
      document.body.classList.toggle("fullscreen-enable");
      if (
        !document.fullscreenElement &&
        /* alternative standard method */ !document.mozFullScreenElement &&
        !document.webkitFullscreenElement
      ) {
        // current working methods
        if (document.documentElement.requestFullscreen) {
          document.documentElement.requestFullscreen();
        } else if (document.documentElement.mozRequestFullScreen) {
          document.documentElement.mozRequestFullScreen();
        } else if (document.documentElement.webkitRequestFullscreen) {
          document.documentElement.webkitRequestFullscreen(
            Element.ALLOW_KEYBOARD_INPUT
          );
        }
      } else {
        if (document.cancelFullScreen) {
          document.cancelFullScreen();
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        } else if (document.webkitCancelFullScreen) {
          document.webkitCancelFullScreen();
        }
      }
    },
    setLanguage(locale, country, flag) {
      this.current_language = locale;
      this.text = country;
      this.flag = flag;
      // i18n.locale = locale;
    },
  },
  watch: {
    type: {
      immediate: true,
      handler(newVal, oldVal) {
        if (newVal !== oldVal) {
          switch (newVal) {
            case "dark":
              document.body.setAttribute("data-topbar", "dark");
              document.body.removeAttribute("data-layout-scrollable");
              break;
            case "light":
              document.body.setAttribute("data-topbar", "light");
              document.body.removeAttribute("data-layout-size", "boxed");
              document.body.removeAttribute("data-layout-scrollable");
              break;
            case "colored":
              document.body.setAttribute("data-topbar", "colored");
              document.body.removeAttribute("data-layout-size", "boxed");
              break;
            default:
              document.body.setAttribute("data-topbar", "dark");
              break;
          }
        }
      },
    },
    width: {
      immediate: true,
      handler(newVal, oldVal) {
        if (newVal !== oldVal) {
          switch (newVal) {
            case "boxed":
              document.body.setAttribute("data-layout-size", "boxed");
              document.body.removeAttribute("data-layout-scrollable");
              break;
            case "fluid":
              document.body.setAttribute("data-layout-mode", "fluid");
              document.body.removeAttribute("data-layout-scrollable");
              document.body.removeAttribute("data-layout-size");
              break;
            case "scrollable":
              document.body.setAttribute("data-layout-scrollable", "true");
              document.body.removeAttribute("data-layout-mode");
              document.body.removeAttribute("data-layout-size");
              break;
            default:
              document.body.setAttribute("data-layout-mode", "fluid");
              break;
          }
        }
      },
    },
  },
};
</script>

<template>
  <header id="page-topbar">
    <div class="navbar-header">
      <div class="d-flex">
        <!-- LOGO -->
        <div class="navbar-brand-box">
          <!-- <router-link to="/" class="logo logo-dark">
            <span class="logo-sm">
              <img src="@/assets/images/sabongfight.png" alt height="22" />
            </span>
            <span class="logo-lg">
              <img src="@/assets/images/sabongfight.png" alt height="17" />
            </span>
          </router-link> -->

          <router-link to='/' class="logo">
      
              <img src="@/assets/images/logos/sitelogo.png"  height="50" />
  
           
          </router-link>
        </div>

        
      </div>
     <h1 class="text-primary">{{ domain }}</h1>



     
      <div class="d-flex">


        

        <b-dropdown
         
          toggle-class="header-item noti-icon"
          variant="black"
        >
          <template v-slot:button-content>
            <i class="bx bx-cog bx-spin toggle-right"></i>
            <!-- <span class="badge bg-danger rounded-pill">{{
              $t("navbar.dropdown.notification.badge")
            }}</span> -->
          </template>
          <!-- item-->
          <b-dropdown-item>
             
            <!-- <span @click="accountStore.editProfile()">
              <i class="bx bx-user font-size-16 align-middle me-1"></i>
              {{ $t("navbar.dropdown.agent.list.profile") }}
              </span> -->
          
          </b-dropdown-item>
        

          <b-dropdown-item>       
          
             
              <p @click="accountStore.changePassword()"> <i class="fas fa-lock mr-4"></i> Password</p>

          </b-dropdown-item>
          <b-dropdown-divider></b-dropdown-divider>
        <b-dropdown-item @click="logOut">
    
              <a class="dropdown-item text-danger">
            <i
              class="bx bx-power-off font-size-16 align-middle me-1 text-danger"
            ></i>
            {{ $t("navbar.dropdown.agent.list.logout") }}
          </a>
      

            </b-dropdown-item>
     
        </b-dropdown>

       
      </div>
    </div>
  </header>
</template>
